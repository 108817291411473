
// eslint-disable-next-line
import * as bootstrap from 'bootstrap';
import $ from 'jquery';
import Vue from 'vue';
export default Vue.extend({
  'methods': {
    show (callback: ()=>void) {
      return new Promise<void>((resolve) => {
        $(this.$refs.modalContent).one('shown.bs.modal', function () {
          if (typeof callback === 'function') {
            callback();
          }
          resolve();
        });

        $(this.$refs.modalContent).modal('show');
      });
    },
    hide (callback: ()=>void) {
      return new Promise<void>((resolve) => {
        $(this.$refs.modalContent).one('hidden.bs.modal', function () {
          if (typeof callback === 'function') {
            callback();
          }
          resolve();
        });

        $(this.$refs.modalContent).modal('hide');
      });
    }
  }
});
