import { Pagination } from '../Pagination';
import { FilterRequest } from './FilterRequest';
import { ApiResponse } from './Response';

// #region list
export interface CustomerListRequest extends FilterRequest {
  dealerId?: number
}

export interface CustomerListDetailResponse {
  'id': number,
  'customer.name': string,
  'customer.customerDetail.ic': string,
  'customer.phone': string,
  'customer.email': string,
  'customer.customerDetail.Address.State': string,
  'customer.createdBy.name': string,
  'customer.createdAt': string,
  'status': string
}

interface CustomerListResult extends Pagination {
  items: Array<CustomerListDetailResponse>
}

export interface CustomerListResponse extends ApiResponse {
  result: CustomerListResult
}
// #endregion

// #region single
export interface CustomerDetailResponse {
  'id': number,
  'name': string,
  'ic': string,
  'phone': string,
  'email': string,
  'dealer': string,
  'address1': string,
  'address2': string,
  'postcode': string,
  'city': string,
  'state': string,
  'createdAt': string,
  'country': string
}

export function createCustomerDetailResponse (): CustomerDetailResponse {
  return {
    'id': 0,
    'name': '',
    'ic': '',
    'phone': '',
    'email': '',
    'dealer': '',
    'address1': '',
    'address2': '',
    'postcode': '',
    'city': '',
    'state': '',
    'country': '',
    'createdAt': ''
  };
}

export interface CustomerResponse extends ApiResponse {
  result: CustomerDetailResponse
}

export function updateCustomerDetails (to: CustomerDetailResponse, from: CustomerDetailResponse): void {
  to.id = from.id;
  to.name = from.name;
  to.ic = from.ic;
  to.phone = from.phone;
  to.email = from.email;
  to.dealer = from.dealer;
  to.address1 = from.address1;
  to.address2 = from.address2;
  to.postcode = from.postcode;
  to.city = from.city;
  to.state = from.state;
  to.country = from.country;

  const dateInUTC = window.moment.utc(from.createdAt).toDate();
  to.createdAt = window.moment(dateInUTC).local().format('DD/MM/YYYY');
}
// #endregion

export interface SearchCustomerByNameResponse extends ApiResponse {
  'result': {
    'customers': CustomerDetailResponse[]
  }
}
export interface SearchCustomerByIcResponse extends ApiResponse {
  'result': CustomerDetailResponse
}
