



























































































































































































































































































































































































































































































































































































































































import printJS from 'print-js';
import ModalTermsAndConditions from '@/components/modals/TermsAndConditions.vue';
import {
  createCustomerDetailResponse,
  updateCustomerDetails
} from '@/types/api/CustomerList';
import {
  WarrantyDropdown,
  WarrantyInfoResponse,
  WarrantyResultAuto,
  WarrantyResultBuilding
} from '@/types/api/Warranty';
import Vue from 'vue';
export default Vue.extend({
  'name': 'CustomerWarranty',
  'components': {
    ModalTermsAndConditions
  },
  'computed': {
    icOrEmail () {
      return Object.getOwnPropertyDescriptor(this.$route.query, 'icOrEmail')
        ? this.$route.query.icOrEmail
        : '';
    },
    warrantyType () {
      return Object.getOwnPropertyDescriptor(this.$route.query, 'warrantyType')
        ? parseInt(this.$route.query.warrantyType + '')
        : 0;
    },
    currentWarrantyDetails ():
      | WarrantyResultBuilding
      | WarrantyResultAuto
      | null {
      const currentWarranty =
        this.warrantyDropdown[this.currentViewingWarranty];

      if (currentWarranty) {
        return currentWarranty.warrantyDetails;
      }
      return null;
    }
  },
  data () {
    const customer = createCustomerDetailResponse();
    return {
      customer,
      'warrantyDropdown': [] as WarrantyDropdown[],
      'currentViewingWarranty': -1 as number,
      'viewingTab': 1
    };
  },
  mounted () {
    this.getCustomerWarranty();

    this.viewTermsAndConditions();
  },
  'methods': {
    selectViewTab (viewTabIndex: number) {
      this.viewingTab = viewTabIndex;
    },
    getCustomerWarranty () {
      this.axios
        .get('warranty/public/list', {
          'params': {
            'icOrEmail': this.icOrEmail
          }
        })
        .then(({ data }) => {
          const response = data as WarrantyInfoResponse;

          updateCustomerDetails(this.customer, response.result.customer);

          if (this.warrantyType === 1) {
            if (
              response.result.buildingWarranties &&
              response.result.buildingWarranties.buildingWarranties.length > 0
            ) {
              const warrantyDropdowns: WarrantyDropdown[] =
                response.result.buildingWarranties.buildingWarranties.map(
                  (w) => {
                    return {
                      'invoice': w.invoice,
                      'warrantyType': 1,
                      'warrantyTypeDisplay': w.warrantyType,
                      'warrantyDetails': w
                    } as WarrantyDropdown;
                  }
                );

              this.warrantyDropdown.push(...warrantyDropdowns);
            }
          } else if (this.warrantyType === 2) {
            if (
              response.result.autoWarranties &&
              response.result.autoWarranties.autoWarranties.length > 0
            ) {
              const warrantyDropdowns: WarrantyDropdown[] =
                response.result.autoWarranties.autoWarranties.map((w) => {
                  return {
                    'invoice': w.invoice,
                    'warrantyType': 2,
                    'warrantyTypeDisplay': w.warrantyType,
                    'warrantyDetails': w
                  } as WarrantyDropdown;
                });

              this.warrantyDropdown.push(...warrantyDropdowns);
            }
          }

          if (this.warrantyDropdown.length > 0) {
            this.currentViewingWarranty = 0;
          } else {
            this.currentViewingWarranty = -1;
          }
        });
    },
    viewTermsAndConditions () {
      (this.$refs.modalTermsAndConditions as any).show();
    },
    printPage () {
      // window.print();
      printJS({
        'printable': 'warrantyInfo',
        'type': 'html',
        'style': 'html { font-family: "Avenir"; } @page {size: landscape; } table { border-collapse: collapse; -webkit-print-color-adjust: exact; } th { font-weight: bold; } th,td { text-align: center; padding: 5px; } tr:nth-of-type(odd) td { background-color: #cccccc; } tr:nth-of-type(even) td {  background-color: transparent; } tr.odd td { background-color: #cccccc !important; } tr.even td { background-color: transparent !important; }',
        'header': 'Customer\'s Warranty'
      });
    },
    getDateDisplay (dateInIso8601: string) {
      const dateInUtc = window.moment.utc(dateInIso8601).toDate();
      return window.moment(dateInUtc).local().format('DD/MM/YYYY');
    }
  }
});
